import { functions } from "../../../utils/firebase";

export const updateUserTeamsUsage = async (userId) => {
  try {
    const dashboardUpdateUserTeamsUsage = functions.httpsCallable(
      "dashboardUpdateUserTeamsUsage"
    );
    const result = await dashboardUpdateUserTeamsUsage({
      userId,
      saveToDb: true,
    });

    if (result.data.status === "max-size-exceeded") {
      return { status: "max-size-exceeded", stats: result.data.stats };
    } else if (result.data.status === "success") {
      return { status: "success", stats: result.data.stats };
    }
  } catch (error) {
    return { status: "error", stats: null };
  }
};
